import invoke from "../invoke";
import "../App.css";

interface RockerProps {
  id: string;
  commands: {
    up: string[];
    down: string[];
  },
  button?: {
    id: string;
    commands: string[];
  };
  hideLabel?: boolean;
  reverse?: boolean;
}

const Rocker = (props: RockerProps) => {

  const pressRocker = async (direction: "up" | "down") => {
    let promises = props.commands[direction].map((command) => invoke("cmd_send_command", {command}));
    await Promise.all(promises);
  };
  return (
    <div className="switch-container" key={props.id}>
      {props.hideLabel || <div className="switch-label">{props.id}</div>}
      <div className="rocker-switch-container">
        <div className="rocker-switch">
          {props.reverse ?
            <>
              <div className="rocker-button up" onClick={() => pressRocker("down")}>&#9650;</div>
              <div className="rocker-button down" onClick={() => pressRocker("up")}>&#9660;</div>
            </>
            :
            <>
              <div className="rocker-button up" onClick={() => pressRocker("up")}>&#9650;</div>
              <div className="rocker-button down" onClick={() => pressRocker("down")}>&#9660;</div>
            </>
          }
        </div>
      </div>


        {props.button && <div className="ctr-button-container"> <div className="ctr-button" onClick={() => {
          let promises = props.button?.commands.map((command) => invoke("cmd_send_command", {command}));
          promises && Promise.all(promises);
        }}>{props.button.id}</div></div>}
      </div>
  );
};

export default Rocker;
