let token = "";

export function setToken(t: string) {
  token = t;
}

async function invoke(key: string, params: any): Promise<any> {
  console.log("Invoking command:", key, params);
  switch (key) {
    case "get_dataref":
      console.log("Getting dataref:", params.dataref);
      let res = await fetch(`https://app.xairline.org/apis/xplm/dataref?dataref_str=${params.dataref}&precision=-1`);
      console.log("Got dataref:", res);
      break;
    case "cmd_send_command":
      await fetch("https://app.xairline.org/apis/xplm/command", {
        "headers": {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        "body": JSON.stringify({
          "command": params.command
        }),
        "method": "PUT"
      });
      break;
    case "get_beacon_data":

      break;
    default:
      console.error("Unknown command:", key);
  }
  return -1;
}

export default invoke;