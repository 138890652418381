import {useEffect, useState} from "react";
import invoke from "./invoke";
import "./App.css";
import Rocker from "./components/rocker";
import LitButton from "./components/litButton";

const ROCKER_ID_TO_COMMAND_STR_MAP: {
  [key: string]: { up: string[]; down: string[] };
} = {
  HDG: {
    up: ["sim/autopilot/heading_up", "sim/GPS/gcu478/hdg_up"],
    down: ["sim/autopilot/heading_down", "sim/GPS/gcu478/hdg_down"],
  },
  CRS: {
    up: ["sim/radios/obs_HSI_up", "sim/GPS/gcu478/crs_up"],
    down: ["sim/radios/obs_HSI_down", "sim/GPS/gcu478/crs_down"],
  },
  ALT: {
    up: ["sim/autopilot/altitude_up", "sim/GPS/gcu478/alt_up"],
    down: ["sim/autopilot/altitude_down", "sim/GPS/gcu478/alt_down"],
  },
  // this is not pitch, it's vertical speed as well
  PITCH: {
    up: ["sim/autopilot/nose_up", "sim/GPS/g1000n3_nose_up"],
    down: ["sim/autopilot/nose_down", "sim/GPS/g1000n3_nose_down"],
  },
  FINE: {
    up: ["sim/GPS/gcu478/inner_up"],
    down: ["sim/GPS/gcu478/inner_down"],
  },
  COARSE: {
    up: ["sim/GPS/gcu478/outer_up"],
    down: ["sim/GPS/gcu478/outer_down"],
  },
  RANGE: {
    up: ["sim/GPS/gcu478/range_up"],
    down: ["sim/GPS/gcu478/range_down"],
  },
  VOLUME: {
    up: ["afm/sr/cmd/switches/vol_Up"],
    down: ["afm/sr/cmd/switches/vol_Dn"],
  },
};

const ID_TO_COMMAND_STR_MAP: { [key: string]: string[] } = {
  A: ["sim/GPS/gcu478/A"],
  B: ["sim/GPS/gcu478/B"],
  C: ["sim/GPS/gcu478/C"],
  D: ["sim/GPS/gcu478/D"],
  E: ["sim/GPS/gcu478/E"],
  F: ["sim/GPS/gcu478/F"],
  G: ["sim/GPS/gcu478/G"],
  H: ["sim/GPS/gcu478/H"],
  I: ["sim/GPS/gcu478/I"],
  J: ["sim/GPS/gcu478/J"],
  K: ["sim/GPS/gcu478/K"],
  L: ["sim/GPS/gcu478/L"],
  M: ["sim/GPS/gcu478/M"],
  N: ["sim/GPS/gcu478/N"],
  O: ["sim/GPS/gcu478/O"],
  P: ["sim/GPS/gcu478/P"],
  Q: ["sim/GPS/gcu478/Q"],
  R: ["sim/GPS/gcu478/R"],
  S: ["sim/GPS/gcu478/S"],
  T: ["sim/GPS/gcu478/T"],
  U: ["sim/GPS/gcu478/U"],
  V: ["sim/GPS/gcu478/V"],
  W: ["sim/GPS/gcu478/W"],
  X: ["sim/GPS/gcu478/X"],
  Y: ["sim/GPS/gcu478/Y"],
  Z: ["sim/GPS/gcu478/Z"],
  SPC: ["sim/GPS/gcu478/spc"],
  BKSP: ["sim/GPS/gcu478/bksp"],
  0: ["sim/GPS/gcu478/0"],
  1: ["sim/GPS/gcu478/1"],
  2: ["sim/GPS/gcu478/2"],
  3: ["sim/GPS/gcu478/3"],
  4: ["sim/GPS/gcu478/4"],
  5: ["sim/GPS/gcu478/5"],
  6: ["sim/GPS/gcu478/6"],
  7: ["sim/GPS/gcu478/7"],
  8: ["sim/GPS/gcu478/8"],
  9: ["sim/GPS/gcu478/9"],
  PLUSMINUS: ["sim/FMS/key_minus"],
  PERIOD: ["sim/FMS/key_period"],
  DCT: ["sim/GPS/gcu478/direct"],
  FPL: ["sim/GPS/gcu478/fpl"],
  CLR: ["sim/GPS/gcu478/clr"],
  MENU: ["sim/GPS/gcu478/menu"],
  PROC: ["sim/GPS/gcu478/proc"],
  ENT: ["sim/GPS/gcu478/ent"],
  FMS: ["sim/GPS/gcu478/fms"],
  COM: ["sim/GPS/gcu478/com"],
  XPDR: ["sim/GPS/gcu478/xpdr"],
  NAVX: ["sim/GPS/gcu478/nav"],
  NAVY: ["sim/autopilot/NAV"],
  SWITCH: ["sim/GPS/gcu478/ff"],
  HDG: ["sim/autopilot/heading"],
  IAS: ["sim/autopilot/speed_hold"],
  MUSIC: ["sim/view/quick_look_1"],
  PILOT: ["sim/view/quick_look_5"],
  MUS1: ["sim/view/quick_look_2"],
  COPILOT: ["sim/view/quick_look_6"],
  MUS2: ["sim/view/quick_look3"],
  PASS: ["sim/view/quick_look_7"],
  MANSQ: ["sim/view/quick_look_4"],
  SPKR: ["sim/view/quick_look_8"],
  VNV: ["sim/autopilot/vnav"],
  FD: ["sim/autopilot/fdir_toggle"],
  YD: ["sim/systems/yaw_damper_toggle"],
  ALT: ["sim/autopilot/altitude_hold"],
  APPR: ["sim/autopilot/approach"],
  VS: ["sim/autopilot/vertical_speed"],
  LVL: [
    "afm/sr/cmd/ap/lvl",
    "sim/autopilot/altitude_hold",
    "sim/autopilot/flight_director_mode_auto",
    "sim/autopilot/servos_and_flight_dir_on",
    "sim/autopilot/servos_on",
    "sim/autopilot/wing_leveler",
  ],
  AP: [
    "afm/sr/cmd/ap/ap",
    "sim/autopilot/fdir_servos_toggle",
    "sim/autopilot/servos_toggle",
    "sim/autopilot/systems/yaw_damper_on",
  ],
  COM1: [
    "sim/audio_panel/select_audio_com1",
  ],
  COM2: [
    "sim/audio_panel/select_audio_com2",
  ],
  NAV1: [
    "sim/audio_panel/select_audio_nav1",
  ],
  NAV2: [
    "sim/audio_panel/select_audio_nav2",
  ],
  MIC1: ["sim/audio_panel/transmit_audio_com1"],
  MIC2: ["sim/audio_panel/transmit_audio_com2"],

};

const ID_TO_INDICATOR_MAP: { [key: string]: any } = {
  FMS: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit/g1000/gcu478_input_sel"});
    return value === 0;
  },
  COM: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit/g1000/gcu478_input_sel"});
    return value === 2;
  },
  XPDR: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit/g1000/gcu478_input_sel"});
    return value === 1;
  },
  NAVX: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit/g1000/gcu478_input_sel"});
    return value === 3;
  },
  HDG: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/autopilot/heading_mode"});
    return value === 1;
  },
  NAVY: async () => {
    let value = await invoke("get_dataref", {dataref: "afm/sr/apLight/nav"});
    return value === 1;
  },
  APPR: async () => {
    let value = await invoke("get_dataref", {dataref: "afm/sr/apLight/apr"});
    return value === 1;
  },
  FD: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/annunciators/flight_director"});
    return value === 1;
  },
  AP: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/annunciators/autopilot"});
    return value === 1;
  },
  YD: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/annunciators/yaw_damper"});
    return value === 1;
  },
  LVL: async () => {
    let value = await invoke("get_dataref", {dataref: "afm/sr/apLight/lvl"});
    return value === 1;
  },
  IAS: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/autopilot/altitude_mode"});
    return value === 5;
  },
  ALT: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/autopilot/altitude_mode"});
    return value === 6;
  },
  VNV: async () => {
    let value = await invoke("get_dataref", {dataref: "afm/sr/apLight/vnv"});
    return value === 1;
  },
  VS: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/autopilot/altitude_mode"});
    return value === 4;
  },
  COM1: async () => {
    // let value = await invoke("get_dataref", {dataref: "afm/sr/lights/g1000COM1"});
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/radios/actuators/audio_selection_com1"});
    return value === 1;
  },
  COM2: async () => {
    // let value = await invoke("get_dataref", {dataref: "afm/sr/lights/g1000COM2"});
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/radios/actuators/audio_selection_com2"});

    return value === 1;
  },
  MIC1: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/radios/actuators/audio_com_selection"});
    return value === 6;
  },
  MIC2: async () => {
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/radios/actuators/audio_com_selection"});
    return value === 7;
  },
  NAV1: async () => {
    // let value = await invoke("get_dataref", {dataref: "afm/sr/lights/g1000NAV1"});
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/radios/actuators/audio_selection_nav1"});
    return value === 1;
  },
  NAV2: async () => {
    // let value = await invoke("get_dataref", {dataref: "afm/sr/lights/g1000NAV2"});
    let value = await invoke("get_dataref", {dataref: "sim/cockpit2/radios/actuators/audio_selection_nav2"});
    return value === 1;
  },

};

const getDisplayNameById = (id: string) => {
  switch (id) {
    case "NAVX":
      return "NAV";
    case "NAVY":
      return "NAV";
    case "SWITCH":
      return "↔";
    case "PLUSMINUS":
      return "+/-";
    case "PERIOD":
      return ".";
    default:
      return id;
  }
};

const getRockerButtonCommands = (id: string) => {
  switch (id) {
    case "HDG":
      return ["sim/autopilot/heading_sync", "sim/GPS/gcu478/hdg_sync"];
    case "CRS":
      return ["sim/GPS/gcu478/crs_sync"];
    case "ALT":
      return ["sim/autopilot/altitude_sync", "sim/GPS/gcu478/alt_sync"];
    case "FINE":
      return ["sim/GPS/gcu478/cursor"];
    case "COARSE":
      return ["sim/GPS/gcu478/cursor"];
    case "RANGE":
      return ["sim/GPS/gcu478/pan_push"];
    case "PUSH":
      return  ["sim/gps/gcu478/cursor"];
    default:
      return [];
  }
};

const FlightControls: React.FC = () => {
  const [beaconData, setBeaconData] = useState<any>(undefined);

  const toggleStatus = (id: string) => {
    btn_click(id);
  };

  async function btn_click(id: string) {
    let promises = ID_TO_COMMAND_STR_MAP[id].map((command) =>
      invoke("cmd_send_command", {command})
    );
    await Promise.all(promises);
  }

  async function get_status() {
    const beaconData = await invoke("get_beacon_data", {});
    setBeaconData(beaconData);
  }

  useEffect(() => {
    setInterval(async () => {
      await get_status();
    }, 10000);
  }, []);


  return (
    <div className="outer-container">
      {/* <nav className="nav-main"></nav> */}
      <section className="content-main">
        <div className="container bg-img">
          <div className="column">
            <div className="knobs">
              {["HDG", "CRS", "ALT"].map((label) => (
                <Rocker
                  id={label}
                  commands={ROCKER_ID_TO_COMMAND_STR_MAP[label]}
                  button={{
                    id: label === "CRS" ? "CTR" : "SYNC",
                    commands: getRockerButtonCommands(label),
                  }}
                />
              ))}
            </div>
          </div>

          <div className="column">
            <div className="row offset">
              <div className="fms-control">
                <div className="column">
                  {["DCT", "FPL", "CLR"].map((label) => (
                    <div className="button-no-lit-container">
                      <button
                        key={label}
                        className="button-no-lit"
                        onClick={() => toggleStatus(label)}
                      >
                        {getDisplayNameById(label)}
                      </button>
                    </div>
                  ))}
                </div>
                <div className="column">
                  {["MENU", "PROC", "ENT"].map((label) => (
                    <div className="button-no-lit-container">
                      <button
                        key={label}
                        className="button-no-lit"
                        onClick={() => toggleStatus(label)}
                      >
                        {getDisplayNameById(label)}
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="fms-control">
                <Rocker
                  id={"FINE"}
                  commands={ROCKER_ID_TO_COMMAND_STR_MAP["FINE"]}
                  button={{
                    id: "PUSH",
                    commands: getRockerButtonCommands("FINE"),
                  }}
                />
                <Rocker
                  id={"COARSE"}
                  commands={ROCKER_ID_TO_COMMAND_STR_MAP["COARSE"]}
                  button={{
                    id: "PUSH",
                    commands: getRockerButtonCommands("COARSE"),
                  }}
                />
              </div>

              <div className="fms-control-block">
                <div className="fms-control">
                  <div className="column">
                    {["FMS", "COM"].map((label) => (
                      <LitButton
                        id={label}
                        displayName={getDisplayNameById(label)}
                        commands={ID_TO_COMMAND_STR_MAP[label]}
                        indicators={ID_TO_INDICATOR_MAP[label]}
                      />
                    ))}
                  </div>
                  <div className="column">
                    {["XPDR", "NAVX"].map((label) => (
                      <LitButton
                        id={label}
                        displayName={getDisplayNameById(label)}
                        commands={ID_TO_COMMAND_STR_MAP[label]}
                        indicators={ID_TO_INDICATOR_MAP[label]}
                      />
                    ))}
                  </div>
                </div>
                <div className="wide-button">
                  {['SWITCH'].map((label) => (
                    <div className="button-no-lit-container">
                      <button
                        key={label}
                        className="button-no-lit wide"
                        onClick={() => toggleStatus(label)}
                      >
                        {getDisplayNameById(label)}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="fms-control">
                <Rocker
                  id={"RANGE"}
                  commands={ROCKER_ID_TO_COMMAND_STR_MAP["RANGE"]}
                  button={{
                    id: "SYNC",
                    commands: getRockerButtonCommands("RANGE"),
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="keyboard">
                <div className="keyboard keyrow">
                  {["A", "B", "C", "D", "E", "F"].map((label) => (
                    <div className="button-no-lit-container">
                      <button
                        key={label}
                        className="button-no-lit"
                        onClick={() => toggleStatus(label)}
                      >
                        {getDisplayNameById(label)}
                      </button>
                    </div>
                  ))}
                </div>
                <div className="keyboard keyrow">
                  {["G", "H", "I", "J", "K"].map((label) => (
                    <div className="button-no-lit-container">
                      <button
                        key={label}
                        className="button-no-lit"
                        onClick={() => toggleStatus(label)}
                      >
                        {getDisplayNameById(label)}
                      </button>
                    </div>
                  ))}
                </div>
                <div className="keyboard keyrow">
                  {["L", "M", "N", "O", "P", "Q"].map((label) => (
                    <div className="button-no-lit-container">
                      <button
                        key={label}
                        className="button-no-lit"
                        onClick={() => toggleStatus(label)}
                      >
                        {getDisplayNameById(label)}
                      </button>
                    </div>
                  ))}
                </div>
                <div className="keyboard keyrow">
                  {["R", "S", "T", "U", "V"].map((label) => (
                    <div className="button-no-lit-container">
                      <button
                        key={label}
                        className="button-no-lit"
                        onClick={() => toggleStatus(label)}
                      >
                        {getDisplayNameById(label)}
                      </button>
                    </div>
                  ))}
                </div>
                <div className="keyboard keyrow">
                  {["W", "X", "Y", "Z", "SPC", "BKSP"].map((label) => (
                    <div className="button-no-lit-container">
                      <button
                        key={label}
                        className="button-no-lit"
                        onClick={() => toggleStatus(label)}
                      >
                        {getDisplayNameById(label)}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="numpad">
                {[
                  ["1", "2", "3"],
                  ["4", "5", "6"],
                  ["7", "8", "9"],
                  ["PERIOD", "0", "PLUSMINUS"],
                ].map((row, rowIndex) => (
                  <div className="row" key={rowIndex}>
                    {row.map((label) => (
                      <button
                        key={label}
                        className="button-no-lit round"
                        onClick={() => toggleStatus(label)}
                      >
                        {getDisplayNameById(label)}
                      </button>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="column">
            <div className="fms-control isolated">
              <div className="column">
                {["HDG"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={ID_TO_INDICATOR_MAP[label]}
                  />
                ))}
              </div>
              <div className="column">
                {["NAVY", "APPR"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={ID_TO_INDICATOR_MAP[label]}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="spacer"></div>

          <div className="column">
            <div className="fms-control isolated">
              <div className="column">
                {["AP", "FD"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={ID_TO_INDICATOR_MAP[label]}
                  />
                ))}
              </div>
              <div className="column">
                {["LVL", "YD"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={ID_TO_INDICATOR_MAP[label]}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="spacer"></div>

          <div className="column">
            <div className="fms-control">
              <div className="column">
                <Rocker
                  id={"PITCH"}
                  commands={ROCKER_ID_TO_COMMAND_STR_MAP["PITCH"]}
                  hideLabel
                  reverse
                />
              </div>
              <div className="column">
                {["IAS", "VS"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={ID_TO_INDICATOR_MAP[label]}
                  />
                ))}
              </div>
              <div className="column">
                {["ALT", "VNV"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={ID_TO_INDICATOR_MAP[label]}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="column">
            <div className="fms-control isolated">
              <div className="column">
                {["COM1", "MIC1"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={ID_TO_INDICATOR_MAP[label]}
                  />
                ))}
              </div>
              <div className="column">
                {["COM2", "MIC2"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={ID_TO_INDICATOR_MAP[label]}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="column">
            <div className="fms-control isolated">
              <div className="column">
                {["NAV1", "PLAY"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={ID_TO_INDICATOR_MAP[label]}
                  />
                ))}
              </div>
              <div className="column">
                {["NAV2", "AUX"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={ID_TO_INDICATOR_MAP[label]}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="column">
            <div className="fms-control">
              <div className="column">
                {["MUSIC", "PILOT"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={async () => true}
                  />
                ))}
              </div>

              <div className="column">
                {["MUS1", "COPILOT"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={async () => true}
                  />
                ))}
              </div>
              <div className="column">
                {["MUS2", "PASS"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={async () => true}
                  />
                ))}
              </div>
              <div className="column">
                {["MANSQ", "SPKR"].map((label) => (
                  <LitButton
                    id={label}
                    displayName={getDisplayNameById(label)}
                    commands={ID_TO_COMMAND_STR_MAP[label]}
                    indicators={async () => true}
                  />
                ))}
              </div>
              <div className="column">
                <Rocker
                  id={"VOLUME"}
                  commands={ROCKER_ID_TO_COMMAND_STR_MAP["VOLUME"]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
       <footer>
         <div className="footer-content">
           <div className="status">
             {beaconData ? `IP: ${beaconData.ip}, Hostname: ${beaconData.hostname}, X Plane Version: ${beaconData.xplane_version}` : "status: not connected"}
             {beaconData
               ? `Connection established with ${beaconData.hostname} `
               : "status: not connected. Start X-Plane-12 or see logs for info."}
           </div>
         </div>
       </footer>
    </div>
  );
};

export default FlightControls;
