import React, {useEffect, useState} from "react";
import {Col, Row, Typography} from "antd";
import FlightControls from "../components/flightControls/App";
import {useLogto} from "@logto/react";
import {setToken} from "../components/flightControls/invoke";

const {Text} = Typography;

interface Props {
}

const Home: React.FC<Props> = (props) => {
    const [clients, setClients] = useState<any>();
    const {isAuthenticated, signIn, signOut, fetchUserInfo, getIdToken} = useLogto();
    useEffect(() => {
      const interval = setInterval(async () => {
        try {
          const response = await fetch("https://app.xairline.org/apis/ws/stats");
          const data = await response.json();
          setClients(data);
        } catch (error) {
          console.error("Error fetching stats:", error);
        }
      }, 5000); // 5000ms = 5 seconds

      getIdToken().then((token) => {
        setToken(token as string);
      });

      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }, []);

    return ((
      <Row style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Col>
          <FlightControls/>
        </Col>
        {/*<Col span={10}>*/}
        {/*  <List*/}
        {/*    header={<div>chatGPT Clients</div>}*/}
        {/*    footer={<div>Total: {clients?.gpt_clients?.length}</div>}*/}
        {/*    bordered*/}
        {/*    dataSource={clients?.gpt_clients}*/}
        {/*    renderItem={(item) => (*/}
        {/*      <List.Item>*/}
        {/*        <Typography.Title level={5}>*/}
        {/*          <>*/}
        {/*            {item}*/}
        {/*          </>*/}
        {/*        </Typography.Title>*/}
        {/*      </List.Item>*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</Col>*/}

      </Row>
    ))
      ;
  }
;

export default Home;
