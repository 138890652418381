import invoke from "../invoke";
import "../App.css";
import {useEffect, useState} from "react";

interface LitButtonProps {
  id: string;
  displayName: string;
  commands: string[];
  indicators: () => Promise<boolean>;
}

const LitButton = (props: LitButtonProps) => {
  const [activeButton, setActiveButton] = useState(false);

  async function btn_click() {
    let promises = props.commands.map((command) => invoke("cmd_send_command", {command}));
    await Promise.all(promises);
  }

  useEffect(() => {
    setInterval(async () => {
      // props.indicators().then((res: any) => {
      //   setActiveButton(res);
      // });
    }, 500);

  }, [props]);

  return (
    <div className="button-container" key={props.id}>
      <div className={`status-indicator ${activeButton ? "active" : ""}`}></div>
      <div className="button-lit-container">
        <button className={`button-lit ${activeButton ? "active" : ""}`}
                onClick={() => btn_click()}>
          {props.displayName}
        </button>
      </div>
    </div>
  );
};

export default LitButton;
