import React, {useEffect, useState} from 'react';
import 'antd/dist/reset.css';
// import "@ant-design/plots/dist/index.css";
import './App.css';
import {Card, Col, ConfigProvider, Image, Layout, Modal, Row, Typography} from 'antd';
import UserInfo from "./components/UserInfo";
import {Link, Route, Routes} from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useLogto} from '@logto/react';
import Callback from "./pages/Callback";
import Markdown from "react-markdown";
import Home from "./pages/Home";

const {Title, Paragraph, Text} = Typography;
const {Content,} = Layout;

const App: React.FC = () => {
  let location = window.location.pathname;
  const screens = useBreakpoint();
  const [token, setToken] = useState("");
  const {isAuthenticated, fetchUserInfo, getAccessTokenClaims, getIdToken} = useLogto();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  }

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const token = await getIdToken();
        setToken(token as string);
      }
    })();
  }, [isAuthenticated, fetchUserInfo]);
  const [current, setCurrent] = useState(
    location === "/" || location === ""
      ? "dashboard"
      : location.split("/")[1]
  );

  return ((
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#006363'
        },
      }}
    >
      <Layout className="layout app">
        <Row style={{background: "#006363"}}>
          <Col flex={"auto"}>
            <Row style={{
              display: "flex",
              height: "100%",
            }}>
              <Link to={"/"}>
                <Image src={"/logo512.png"}
                       style={
                         {
                           maxHeight: "8vh",
                           objectFit: "contain",
                           margin: "12px 24px 12px"
                         }
                       }
                       preview={false}
                >

                </Image>
              </Link>

            </Row>
          </Col>
          {
            !screens.sm ? <></> :
              <Col flex={"auto"}>
                <Link to={"/"}>
                  <Title level={1} style={{
                    color: "white", display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "100%",
                  }}>
                    X Web Proxy
                  </Title>
                </Link>
              </Col>
          }
          <Col flex={"auto"}>
            <Row style={{
              display: "flex",
              justifyContent: !screens.sm ? "center" : "flex-end",
              alignItems: "center",
              height: "100%",
              marginRight: !screens.sm ? "12px" : "24px",
              marginLeft: !screens.sm ? "12px" : "24px",
            }}>
              <Col span={6}>
                <Modal
                  title="Download"
                  open={isModalOpen}
                  onOk={handleOk}
                  cancelButtonProps={{style: {display: "none"}}}
                >
                  < Markdown>
                    {`
# Download and install plugin
[Download](https://github.com/xairline/xa-ng/releases/latest/download/XWebStack.zip) and unzip the plugin to your X-Plane plugin folder.
# Access Token

\`
${token ? "CLIENT_TOKEN=" + token : "Login/Sign up to get your access token."}
\`

# Configuration
Copy above token, including **CLIENT_TOKEN=**, and past it into the **config** file under **PATH_TO_XPLANE/Resources/plugins/XWebStack** folder.

# Access GPTs
[GPTs](https://chatgpt.com/g/g-6797a8db4be0819197a1b2ea9bb566a6-x-gpt)                      
`}
                  </Markdown>

                </Modal>
                <a href={"#"} onClick={() => {
                  setIsModalOpen(true)
                }}>
                  <Title
                    level={screens.xxl ? 2 : 5}
                    italic={true}
                    style={{
                      marginTop: "24px",
                      color: "white"
                    }}>
                    chatGPT
                  </Title>
                </a>
              </Col>
              <Col span={10}>
                <a href={"https://github.com/xairline/xa-ng/releases/latest"}>
                  <Title
                    level={screens.xxl ? 2 : 5}
                    italic={true}
                    style={{
                      marginTop: "24px",
                      color: "white"
                    }}>
                    Download
                  </Title>
                </a>
              </Col>
              <Col span={8}><UserInfo/></Col>
            </Row>
          </Col>
        </Row>

        <Layout>
          <Content
            style={{
              padding: 24,
              minHeight: 280,
              background: "white",
              overflow: "hidden",
            }}
          >
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/callback" element={<Callback/>}/>
              <Route path="/xgot/privacy-policy" element={
                <Card>
                  <Typography>
                    <Title>Privacy Policy for xGOT</Title>
                    <h3>Last Updated:March 2025</h3>
                    <Paragraph>
                      xGOT is developed by xAirline and xGeoff. We are committed to protecting your privacy and ensuring
                      transparency about how our app operates.
                    </Paragraph>

                    <h2>Information We Collect</h2>
                    <Paragraph>
                      xGOT does not collect, store, or share any personal data. The app only records crash reports or
                      bug
                      encounters to improve user experience. This data is not stored on a device or server and is not
                      shared
                      with third parties.
                    </Paragraph>
                    <h2>
                      How xGOT Connects to X-Plane 12
                    </h2>
                    <Paragraph>
                      The app requires a Wi-Fi connection to communicate with X-Plane 12 using its SDK/API.
                      xGOT only reads and writes data to and from X-Plane 12 to facilitate functionality.
                      No user information is transmitted, stored, or processed outside this interaction.
                    </Paragraph>
                    <h2>
                      Internet & Third-Party Services
                    </h2>
                    <Paragraph>
                      xGOT requires an internet connection only to detect whether X-Plane 12 is running via Wi-Fi.
                      The app does not use cookies, tracking technologies, or third-party analytics tools.
                    </Paragraph>
                    <h2>
                      Compliance with GDPR & CCPA
                    </h2>
                    <Paragraph>
                      xGOT complies with GDPR and CCPA regulations.
                      While the app does not store personal data, users can request data deletion should any data
                      storage
                      practices change in the future.
                    </Paragraph>
                    <h2>
                      Changes to This Privacy Policy
                    </h2>
                    <Paragraph>
                      This Privacy Policy may be updated as needed. We encourage users to check for updates
                      periodically.
                    </Paragraph>
                    <h2>
                      Contact Us
                    </h2>
                    <Paragraph>
                      For questions regarding this policy, please contact us at: <Text>admin@xairline.org</Text>
                    </Paragraph>
                  </Typography>
                </Card>
              }/>
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  ))
    ;
};

export default App;
